import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger'; 
import { ScrollSmoother } from 'gsap/ScrollSmoother'; 
gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollSmoother);
  
import testjs from './testjs';
testjs();
jQuery(document).ready(function ($) {    

	modernizer: {
        if (!Modernizr.objectfit) {
            $('.object-fit').each(function () {
                var $container = $(this),
                    imgUrl = $container.find('img').prop('src');
                if (imgUrl) {
                    $container
                        .css('backgroundImage', 'url(' + imgUrl + ')')
                        .addClass('not-compatible');
                }
            });
        }
	}

	smoothScroller: {
		ScrollSmoother.create({
			smooth: 2,               // how long (in seconds) it takes to "catch up" to the native scroll position
			effects: true,           // looks for data-speed and data-lag attributes on elements
			smoothTouch: 0.1,        // much shorter smoothing time on touch devices (default is NO smoothing on touch devices)
		  });
	}

	customCursor: {
		$('.cursor-title').mouseover(() => {
			$(".ball").css('display', 'block');
			gsap.set(".ball", {xPercent: -50, yPercent: -50});

			const ball = $(".ball");
			
			const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
			const mouse = { x: pos.x, y: pos.y };
			const speed = 0.2;

			const xSet = gsap.quickSetter(ball, "x", "px");
			const ySet = gsap.quickSetter(ball, "y", "px");

			window.addEventListener("mousemove", e => {   
			  $(".ball").css('top', $(document).scrollTop());
			  mouse.x = e.x;
			  mouse.y = e.y;  
			});

			gsap.ticker.add(() => {
			
			  // adjust speed for higher refresh monitors
			  const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
			
			  pos.x += (mouse.x - pos.x) * dt;
			  pos.y += (mouse.y - pos.y) * dt;
			  xSet(pos.x);
			  ySet(pos.y);
			});
		}); 
		$('.cursor-title').mouseout(function() {
			$(".ball").css('display', 'none');
		});


		if($('body').hasClass('error404')) {
			gsap.set(".ball", {xPercent: -50, yPercent: -50});

			const ball = $(".ball");
			
			const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
			const mouse = { x: pos.x, y: pos.y };
			const speed = 0.2;

			const xSet = gsap.quickSetter(ball, "x", "px");
			const ySet = gsap.quickSetter(ball, "y", "px");

			window.addEventListener("mousemove", e => {   
			//   $(".ball").css('top', $(document).scrollTop());
			  mouse.x = e.x;
			  mouse.y = e.y;  
			});

			gsap.ticker.add(() => {
			
			  // adjust speed for higher refresh monitors
			  const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 
			
			  pos.x += (mouse.x - pos.x) * dt;
			  pos.y += (mouse.y - pos.y) * dt;
			  xSet(pos.x);
			  ySet(pos.y);
			});
		}; 		
	}

	anchorlinks: {
		$(document).on('click', '.scroll-to', function (event) {
			event.preventDefault();

			$('html, body').animate({
				scrollTop: $($.attr(this, 'href')).offset().top
			}, 500);
		});
	}

	externallinks: {
		$('a[href^="mailto:"]').each(function () {
			$(this).addClass('email-link');
		});	 
		
		$('a:not(.email-link):not([class^="magnific-"])').each(function () {
			var a = new RegExp('/' + window.location.host + '/');
			
			if (!a.test(this.href)) {
				$(this).click(function (event) {
				event.preventDefault();
				window.open(this.href, '_blank');
				});
			}
		});
		pdfs: {
			// Open PDFs in new window
			$(function () {
				$('a[href$=".pdf"]').prop('target', '_blank');
			});
		}
	}

	slider: {
		$('.js-portfolio-slider').each(function() {
			$(this).slick({
				dots: false,
				arrows: true,
				fade: false,
				autoplay: false,
				autoplaySpeed: 3500,
				speed: 1200,
			});
		})
	}

	button: {
		$('.button').each(function() {
			var text = this.innerText;
			$(this).wrapInner('<span title="'+text+'"></span>');
		})
	}
	
	magnific: {
		// INLINE
		$('.magnific-inline-popup').magnificPopup({
            type: 'inline',

			fixedContentPos: false,
			fixedBgPos: true,

			overflowY: 'auto',

			closeBtnInside: true,
			preloader: false,
			
			midClick: true,
			removalDelay: 300,
			mainClass: 'mfp-fade'
        });

		// GALLERY
		$('.magnific-gallery-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-mobile',
			image: {
				verticalFit: true,
				titleSrc: function(item) {
					return item.el.attr('title');
				}
			},
			gallery:{
				enabled:true
			}
		});

		$('.magnific-gallery-single-image').magnificPopup({
			type: 'image',
			closeOnContentClick: true,
			mainClass: 'mfp-img-single',
			image: {
				verticalFit: true,
				titleSrc: function titleSrc(item) {
				return item.el.attr('title');
				}
			},
			gallery: {
				enabled: false
			}
		});   

		//  video
		$('.magnific-video').magnificPopup({
			type: 'iframe',
			mainClass: 'mfp-video-wrapper',
			removalDelay: 160,
			preloader: false,
			fixedContentPos: false,
		
			iframe: {
				markup: '<div class="mfp-iframe-scaler">' +
					'<div class="mfp-close"></div>' +
					'<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
					'</div>',
				patterns: {
					youtu: {
						index: 'youtu.be',
						id: function( url ) {
						
							// Capture everything after the hostname, excluding possible querystrings.
							var m = url.match( /^.+youtu.be\/([^?]+)/ );
					
							if ( null !== m ) {
								return m[1];
							}
					
							return null;
				
						},
						// Use the captured video ID in an embed URL. 
						// Add/remove querystrings as desired.
						src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
					},
					youtube: {
						index: 'youtube.com/',
						id: 'v=',
						src: 'https://www.youtube.com/embed/%id%?autoplay=1'
					},
					vimeo: {
						index: 'vimeo.com/', 
						id: function(url) {        
							var m = url.match(/(https?:\/\/)?(www.)?(player.)?vimeo.com\/([a-z]*\/)*([0-9]{6,11})[?]?.*/);
							if ( !m || !m[5] ) return null;
							return m[5];
						},
						src: 'https://player.vimeo.com/video/%id%?autoplay=1'
					}
				}
			}
		});
	}
	homepageGradient: {
		if($('.blue-gradient').length) {			

			$('.blue-gradient').each(function() {
				var trigger = this;
				var body = $('body');
				gsap.to(body, {					
					backgroundColor: '#1A17A3',
					scrollTrigger: {
						trigger: trigger,						
						start: "top bottom", // when the top of the trigger hits the top of the viewport
						// end: "bottom center"			
						// once: true		  ,
						scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar					  
					  }
				  });
			})
		}
		
	}
	fullpageScroller: {
		if($('.js-scroller-section').length) {

			var scroller = $('.js-scroller-section');			
			var image1 = $('.js-scroller-section-1 .js-scroller-image');
			var image2 = $('.js-scroller-section-2 .js-scroller-image');
			var image3 = $('.js-scroller-section-3 .js-scroller-image');
			var title1 = $('.js-scroller-section-1 .js-scroller-title');
			var title2 = $('.js-scroller-section-2 .js-scroller-title');
			var title3 = $('.js-scroller-section-3 .js-scroller-title');
			
			var tl = gsap.timeline({
				scrollTrigger: {
					trigger: scroller,
					start: "top top",
					end: "+=" + 3 * window.innerHeight,
					pin: true,
					scrub: true,
					// snap: true,
				}
			})

			tl.to(image1, {
				yPercent: -100,
				ease: "power4.out",
				opacity: 0,
			}, "+=1")
			tl.to(title1, {				
				ease: "power4.out",
				opacity: 0,
				x: -70
			}, "<")
			tl.fromTo(image2, {
				yPercent: 100,				
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				ease: "power4.out",
			}, ">-=0.35")
			tl.fromTo(title2, {
				x: 150,				
				opacity: 0,				
			}, {
				x: 0,
				opacity: 1,
				ease: "power4.out",
			}, "<")
			tl.to(image2, {
				yPercent: -100,
				ease: "power4.out",
				opacity: 0,
			}, ">+=1")
			tl.to(title2, {				
				ease: "power4.out",
				opacity: 0,
				x: -70
			}, "<")
			tl.fromTo(image3, {
				yPercent: 100,			
				opacity: 0,
			}, {
				yPercent: 0,
				opacity: 1,
				ease: "power4.out",
			},  ">-=0.35")
			tl.fromTo(title3, {
				x: 150,				
				opacity: 0,
			}, {
				x: 0,
				opacity: 1,
				ease: "power4.out",
			}, "<")

		}
	}

	expandingVideo : {
		var videoContainer = $('.js-expanding-video-section');
		var video = $('.js-expanding-video');
		var section = $('.expanding-video');
		gsap.to(video, {
			scale: 0.95,
			duration: 1,
			// width: "100vw",
			ease: 'none',
			scrollTrigger: {
				trigger: section,								
				start: "top top",
				end: '+='+ 2 * window.innerHeight,
				pin: section,
            	pinSpacing: true,
				scrub: true,
				// markers: true,
			},
		});

	}

	tickertapeText: {		 
		$('.portfolio-tickertape ul li').wrapAll('<span class="ticker-items">');
		var tickerWidth = $('.portfolio-tickertape ul').width();
		var spanWidth = $('.portfolio-tickertape ul span').width();
		$('.portfolio-tickertape ul span').clone().appendTo('ul');
		$('.portfolio-tickertape ul span').wrapAll('<span class="ticker-wrapper">');
		
		// gsap.set( '.ticker-wrapper' , {x: tickerWidth } );
		if($('body').hasClass('home')) {
		var tl = gsap.timeline( {repeat: -1} );
		tl.to( '.ticker-wrapper' , 0.75 , 
			  {
				x:"-=50", 
				ease: 'none', 
				onComplete: function(){
				  var style = window.getComputedStyle( $('.ticker-wrapper')[0] );
				  var matrix = new WebKitCSSMatrix( style.webkitTransform );
				  if( matrix.m41 < 0-spanWidth ){
					  gsap.set( '.ticker-wrapper' , {x: 0} );
				   }
				  this.invalidate();
				},
			  }
		);
	}
	}

	scrollingAnimations: {
		if($('.block').length) {
			$('.block').each(function() {
				var trigger = $(this)								
			
				  var introTitle = $(this).find('.js-intro-scroll-title');
				  var wwdTitle1 = $(this).find('.js-wwd-title-1');
				  var wwdTitle2 = $(this).find('.js-wwd-title-2');
				  var contactTitle = $(this).find('.js-contact-title');

				  if(introTitle.length) {
					gsap.from (introTitle, {
						x: "100vw",
						scrollTrigger: {						
							scrub: 1,
							once: true,
							trigger: trigger,
							start: "top bottom",
							//   pin: trigger,
							//   end: "+=" + (window.innerWidth),
							end: "bottom bottom",
						}
					})
				  }
				  if(wwdTitle1.length) {
					gsap.from (wwdTitle1, {
						x: "130vw",
						scrollTrigger: {						
							scrub: 1,
							once: false,
							trigger: trigger,
							start: "top top+=150",
							pin: trigger,
						    end: "+=" + (wwdTitle1.width() * 1.7) ,
							// end: "bottom bottom",
						}
					})
				  }
				  if(wwdTitle2.length) {
					gsap.from (wwdTitle2, {
						x: "-110vw",
						scrollTrigger: {	
							scrub: 1,
							once: false,
							trigger: trigger,
							start: "top top+=150",
							// pin: trigger,
						    end: "+=" + (wwdTitle1.width() * 1.7) ,				
							
						}
					}, "")
				  }
				  if(contactTitle.length) {
					var scrubEnd = (window.innerWidth) ;

					gsap.from (contactTitle, {
						x: "-90vw",
						duration: 1.5,
						scrollTrigger: {						
							// scrub: 1,
							once: true,
							trigger: trigger,
							start: "top center",							
							
							
							// onLeave: function(self) {
							// 	self.disable()
							// 	self.animation.progress(1)
							// }
							// end: "bottom bottom",
						}
					})
				  }
			});
		}
	}
});


