
function testjs() {
    if(document.querySelector('body').classList.contains('page-id-118')) {


async function printCharacterGrid(googleDocUrl) {
  try {
    // Use fetch to get the document content
    const response = await fetch(googleDocUrl);
    const html = await response.text();
    
    // Parse the document using DOMParser
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    console.log(doc);
    // Set up arrays to store character data
    const xCoord = [];
    const character = [];
    const yCoord = [];
    
    // Find all table rows from the document
    const rows = doc.querySelectorAll('tr');
    
    // Loop through each row
    rows.forEach(row => {
      // Get all td cells in this row
      const cells = row.querySelectorAll('td');
      
      // Extract text from each cell and add to appropriate array
      if(cells.length === 3) {
        xCoord.push(cells[0].querySelector('span').textContent);
        character.push(cells[1].querySelector('span').textContent);
        yCoord.push(cells[2].querySelector('span').textContent);
      }
    });
    
    // Remove first element from each array as it contains header information
    xCoord.shift();
    character.shift();
    yCoord.shift();

    // Error handling if no character data is found
    if (xCoord.length === 0 || yCoord.length === 0 || character.length === 0) {
      console.error('No character data found in the document');
      return;
    }
    
    // Convert coordinates to numbers and find max
    const maxX = Math.max(...xCoord.map(x => parseInt(x)));
    const maxY = Math.max(...yCoord.map(y => parseInt(y)));
    
    // Create grid filled with spaces
    const grid = Array(maxY + 1).fill().map(() => Array(maxX + 1).fill(' '));
    
    // Place characters in the grid using the parallel arrays
    for(let i = 0; i < character.length; i++) {
      const x = parseInt(xCoord[i]);
      const y = parseInt(yCoord[i]);
      grid[y][x] = character[i];
    }
    
    // Print the grid
    grid.forEach(row => {
      console.log(row.join(''));
    });
  } catch (error) {
    console.error('Error:', error.message);
  }
}

// Example usage
printCharacterGrid('https://docs.google.com/document/d/e/2PACX-1vQGUck9HIFCyezsrBSnmENk5ieJuYwpt7YHYEzeNJkIb9OSDdx-ov2nRNReKQyey-cwJOoEKUhLmN9z/pub');


    }
}

export default testjs;